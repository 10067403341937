import React from 'react';
import Section from '../components/Section';
import Metadata from '../components/Metadata';

function abavb() {
  return (
    <>
      <Metadata title='ABAVB' />
      <Section heading='Applied Behavior Analysis (ABA)'>
        <p>
          Aus unserer fachlichen Einschätzung und Erfahrung kann die Applied
          Behavior Analysis (ABA) hier einen geeigneten Rahmen und eine Fülle
          von Methoden bieten. Die Bedürfnisse der Klient:innen sind so
          mannigfaltig, wie es auch die Methodenvielfalt in der angewandten
          Verhaltensanalyse ist.
        </p>
        <p>
          Applied Behavior Analysis (ABA) ist eine Wissenschaft, die sich damit
          beschäftigt, menschliches Verhalten zu verstehen und bedeutsame
          Veränderungen in diesem zu begünstigen. Der theoretische Ansatz geht
          davon aus, dass unser Verhalten durch unsere Umwelt bestimmt wird, so
          dass durch Änderungen in der Sozial- und Lernumgebung einer Person
          sich auch ihr Verhalten verändert und Lernen begünstigt werden kann.
        </p>
        <p>
          In der Anwendung der Methoden nach ABA stehen Verhaltensweisen und
          Fähigkeiten, die für die jeweilige Person von Bedeutung sind, im Fokus
          und sollen zu ihrem persönlichen Vorteil aufgebaut, erhalten oder
          verändert werden. Dies geschieht durch die Einbeziehung individueller
          Bedürfnisse und die Anwendungen von wissenschaftlich fundierten
          Methoden sowie der fortlaufenden Analyse der Faktoren, die zu
          Veränderungen führten.
        </p>
        <p>
          ABA lässt sich weltweit in Bachelor- und Masterstudiengängen
          studieren. Diese wissenschaftliche Disziplin, die sich im
          Gesundheitssektor einordnen lässt, findet vor allem im
          englischsprachigen Raum in verschiedenen Bereichen Anwendung, wie z.B.
          bei Behandlungen von Phobien, Angststörungen, Drogenabhängigkeit,
          Essstörungen, Demenz, in Sozialtrainings oder auch im Organisations-
          und Managementtraining. Weltweit sind Methoden nach ABA am häufigsten
          in der Förderung von Personen mit Autismus zu finden.
        </p>
        <p>
          Wichtig zu betonen ist, dass es die ABA nicht gibt. Unter dem
          Oberbegriff ABA lässt sich eine sehr große Methodenvielfalt finden und
          ABA ist nicht gleichbedeutend mit Lerneinheiten am Tisch (Discrete
          Trial Teaching) oder Belohnungssystemen, wie es oft dargestellt wird.
          ABA ist eine Analyse, die es ermöglicht, evidenzbasierte Methoden so
          anzuwenden, dass sie bestmöglich wirksam für die Person im Mittelpunkt
          der Förderung sind. Welche Methoden ausgewählt und angewendet werden,
          hängt natürlich von den Ausführenden ab und variiert mit deren
          Qualifikation, Berufserfahrung, Werten und Umsetzung der Berufsethik.
        </p>
      </Section>

      <Section heading='Verbal Behavior (VB)'>
        <p>
          Ein großer Fokus in der Anwendung von ABA liegt darüber hinaus auf der
          Umsetzung des Verbal Behavior (VB)-Ansatzes, welcher kurzgefasst die
          Förderung der Sprache beinhaltet. Der VB-Ansatz versteht unter Sprache
          nicht nur die gesprochene Sprache, sondern auch andere Formen der
          Kommunikation, wie z.B. elektronische Kommunikationssysteme,
          Symbolkarten oder Gebärden. Eines der Hauptziele ist es, dass der/die
          Klient:in eine effektive Form der Kommunikation erlernt. Des Weiteren
          werden auch andere sprachliche Kategorien gefördert, wie z.B. das
          Sprachverständnis, das Benennen und die verbale Imitation. Die
          Förderung findet in natürlichen Situationen, geleitet von der
          Motivation des/der Klient:in statt.
        </p>
      </Section>
    </>
  );
}

export default abavb;
